(function($) {

$.widget('custom.tabs', {

    _create: function() {
        this.registerClickEvent();
        this.load();
    },
    registerClickEvent: function(options) {
        var widget = this;
        this.element.find('.tabs-tabs .tabs-tab').click(function(event) {
            event.preventDefault();
            var $tab = $(this);
            var reference = $tab.attr('aria-controls');
            widget.select(reference);
        });
    },
    select: function(id) {
        if ( ! id) {
            id = this.getReferenceForItemAtIndex(0);
        }
        this.updateTabsWithSelectionId(id);
        this.updateContentWithSelectionId(id);
        this.updateCookiesWithSelectionId(id);
    },
    getReferenceForItemAtIndex: function(index) {
        var $tabs = this.element.find('.tabs-tabs .tabs-tab');
        var $tab = $($tabs[index]);
        var reference = $tab.attr('aria-controls');
        return reference;
    },
    updateTabsWithSelectionId: function(id) {
        this.element.find('.tabs-tabs .tabs-tab').each(function() {
            var $tab = $(this);
            var reference = $tab.attr('aria-controls');
            var active = (reference == id);
            $tab.toggleClass('active', active);
        });
    },
    updateContentWithSelectionId: function(id) {
        this.element.find('.tabs-destination .tabs-content').each(function() {
            var $content = $(this);
            var reference = $content.attr('id');
            var active = (reference == id);
            $content.toggle(active);
        });
    },
    updateCookiesWithSelectionId: function(id) {
        var location = window.location;
        if (location.href.indexOf('create') == -1) {
            $.cookie('tabs.active.' + location, id);
        }
    },
    load: function() {
        var location = window.location;
        var id = null;
        if (location.href.indexOf('create') == -1) {
            id = $.cookie('tabs.active.' + location);
        }
        this.select(id);
    }

});

})(jQuery);
